/**
 * @file AboutUsPage.tsx
 * @description About us page
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import infraestructura from '../image/infraestructura.png'
import canales from '../image/canales.png'
import ioplay from '../image/ioplay.png'
import { Fade } from 'react-reveal'
import React from 'react'

const AboutUsPage = (): JSX.Element => {
  return (
    <>
      <Header page="Sobre IoPLAY" />
      <Main className="min-h-[calc(100vh-370px)] bg-1 bg-no-repeat bg-cover py-[0!important] flex justify-end flex-col">
        <div className="bg-[#f3f3f3] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden py-[80px] sm:gap-[50px] md:rounded-br-[200px]">
          <Fade left>
            <div className='md:justify-self-end max-w-[622px] sm:row-start-1'>
              <p className='font-bold text-[2.8rem] sm:text-[2.6rem] flg'>I<em>o</em>PLAY</p>
              <p className='flg text-justify max-w-[1400px]'>Es una empresa especializada en la realización de proyectos de consultoría y desarrollo operativo, sobre plataformas tecnológicas, para proveer sistemas informáticos, aplicaciones Web Enable, convergencia tecnológica,  equipamiento homologado, y dispositivos especializados, que administren, controlen y gestionen,  la operación de cable operadores, ISP [Proveedor Servicios Internet], productores de contenido multimedia, generadores de contenido multimedia, y operaciones de tecnológicas propias <em>y</em> <em>o</em> hibridas.</p>
            </div>
          </Fade>
          <Fade right><img src={ioplay} alt="IoPLAY" className='md:ml-[100px] md:justify-self-start w-[35vw] sm:w-full' /></Fade>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover py-[80px] sm:gap-[50px]">
          <Fade left><img src={canales} alt="Programas" className='md:mr-[100px] md:justify-self-end w-[35vw] sm:w-full' /></Fade>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <p className='font-bold text-[2.8rem] sm:text-[2.6rem]'>PROGRAMAS</p>
              <p className='flg text-justify max-w-[1400px]'>Las plataformas <span>TvMUX</span> <span>IpMUX</span>, gestionan y administran el contenido de diferentes planes técnicos, y ofertas comerciales. Ponen a disposición, diferenciadores de ofertas legítimas, permitiendo proveer contenido que, por normatividad, regulación y costes con programadoras, solo pueden ser vistos por clientes categorizados en los planes comerciales, que reflejan un diferenciador significativo, de programación exclusiva, sin que sea excluyente de las parrillas de programación de contenido básico. <span>TvMUX</span> <span>IpMUX</span>, permiten seleccionar los contenidos provistos a los suscriptores, mediante la segmentación programática, de planes y ofertas comerciales acordes con la contratación base, con programadoras de contenido, lo cual debe ser reflejado en la asignación de contenido ofrecido y de pago.</p>
            </div>
          </Fade>
        </div>
        <div className='flex flex-col justify-center items-center w-full py-[50px] px-[10px] bg-[#f3f3f3] md:rounded-br-[200px] md:rounded-tl-[200px]'>
          <span className='text-[2.6rem] sm:text-[2rem] font-bold text-center'>INFRAESTRUCTURA GLOBAL</span>
          <p className='flg text-justify max-w-[1400px]'>I<em>o</em>PLAY, suministra aplicaciones para manejo total de la operación como proveedor de contenido multimedia [Televisión <span>IpTV</span> <span>OTT</span>], servicios de transporte de datos y servicios de acceso a Internet. La plataforma global, I<em>o</em>PLAY provee los medios de apertura necesarios, para acceso a las vías de monetización mediante, el uso eficiente de herramientas que permiten el acceso total a información, que suministra lecturas, mediante el uso de reportería nativa, la generación del conocimiento de las métricas ARPU [Ingreso Promedio Por Usuario] y CHURN [Tasa De Cancelación De Clientes] que permiten tomar decisiones en torno a las políticas de sostenibilidad financiera.</p>
          <img src={infraestructura} alt="Infraestructura" />
        </div>
        <div className='w-full h-auto sm:px-[10px] md:px-[50px] lg:px-[150px] py-[50px] relative bg-[#ffffff] md:rounded-tl-[200px]'>
          <div className='w-[50%] sm:w-full'>
            <span className='flg text-[2.6rem] z-[1] relative font-bold text-[#0794d9]'>IoPLAY</span>
            <p className='flg text-[1.2rem] text-justify z-[1] relative'>I<em>o</em>PLAY, consolida plataformas operativas, con la misión de facilitar a los cable operadores, ISP, operadores de contenido multimedia y productores de contenido multimedia, el acceso a tecnologías para utilización eficiente, de recursos comerciales, financieros, logísticos, administrativos, mediante aplicaciones y herramientas para la transmisión de contenidos licenciados o abiertos, para infraestructuras de redes DvB[c], DvB[s], DvB[t], transporte de datos, suministros para servicios de acceso a Internet y televisión digital en plataformas [<span>OTT</span> <span>IpTV</span>].</p>
          </div>
          <div className='bg-aboutUs bg-center w-full h-full absolute top-0 left-0 opacity-[.8] z-0 sm:opacity-[.4] bg-no-repeat'></div>
        </div>
      </Main>
      <Footer page="Sobre IoPLAY" />
    </>
  )
}

export default AboutUsPage