/**
 * @file SuscriberTerminalsPage.tsx
 * @description Page for Suscriber Terminals
 */

import Main from '../../components/Main.tsx'
import Footer from '../../components/Footer.tsx'
import Header from '../../components/Header.tsx'
import suscriptores from '../../image/suscriptores.png'
import allwinner from '../../image/allwinner.png'
import rockchip from '../../image/rockchip.png'
import amlogic from '../../image/amlogic.png'
import marcas from '../../image/marcas.png'
import { Fade, Zoom } from 'react-reveal'
import React from 'react'

const SuscriberTerminalsPage = (): JSX.Element => {
  return (
    <>
      <Header page="Suscriptores" />
      <Main className="py-[0!important]">
        <div className="h-[calc(100vh-70px)] min-h-[700px] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover">
          <Zoom><img src={suscriptores} className='md:justify-self-end md:mr-[100px]' alt="Decodificadores" /></Zoom>
          <Fade right><div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
            <span className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>Decodificadores</span>
            <p className='flg text-justify'>El acceso a los servicios <span>IpTV</span> <span>OTT</span>, se realiza mediante la utilización de equipos terminales <span>TvBOX</span>, basados en sistema operativo <span>Android</span>, versiones <em>7</em>.x <em>8</em>.x <em>9</em>.x <em>10</em>.x <em>11</em>.x <em>12</em>.x. El acceso desde otras plataformas como <span>Tizen</span> <span>WebOS</span> <span>IOS</span>, está basado en desarrollos exclusivos, y bajo condiciones de versión y migración preestablecidas por el cable operador.</p>
          </div></Fade>
        </div>
        <div className='flex flex-col items-center bg-[#f3f3f3] px-[10px] overflow-hidden py-[30px] md:rounded-br-[200px]'>
          <span className='text-[2.6rem] text-[#0794d9] font-bold text-center'>Dispositivos TvBOX Homologados</span>
          <p className='flg'>La APP I<em>o</em>PLAY <em>GO</em>, corre sobre dispositivos <span>TvBOX</span> con las siguientes características técnicas básicas.</p>
          <div className='grid grid-cols-2 sm:grid-cols-1 gap-[20px] xs:text-[.9rem] w-full mt-[20px]'>
            <Fade left>
              <div className='bg-[#fff] min-h-[100px] flex justify-center flex-col py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] w-full md:max-w-[450px] px-[10px] sm:min-w-full justify-self-end'>
                <span className='text-[2rem] font-bold flg'>Servicio <span>IpTV</span></span>
                <div className='w-full flex justify-between gap-[5px]'><span>RAM</span><span>02 GB</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>ROM</span><span>04 GB</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>INALAMBRICO</span><span className='flg sm:text-right'><span>2.4</span> <span>5.8</span> GHZ [Opcional]</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>CABLEADO</span><span className='flg sm:text-right'><span>10</span> <span>100</span> <span>1000</span> MBPS [Sugerido]</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>TRANSMISION</span><span className='flg sm:text-right'><span>MULTICAST</span> <span>UNICAST</span></span></div>
              </div>
            </Fade>
            <Fade right>
              <div className='bg-[#fff] min-h-[100px] flex justify-center flex-col py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] w-full md:max-w-[450px] px-[10px] sm:min-w-full justify-self-start'>
                <span className='text-[2rem] font-bold flg'>Servicio <span>OTT</span></span>
                <div className='w-full flex justify-between gap-[5px]'><span>RAM</span><span>02 GB</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>ROM</span><span>04 GB</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>INALAMBRICO</span><span className='flg sm:text-right'><span>2.4</span> <span>5.8</span> GHZ [Opcional]</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>CABLEADO</span><span className='flg sm:text-right'><span>10</span> <span>100</span> <span>1000</span> MBPS [Sugerido]</span></div>
                <div className='w-full flex justify-between gap-[5px]'><span>TRANSMISION</span><span className='flg sm:text-right'><span>MULTICAST</span> <span>UNICAST</span></span></div>
              </div>
            </Fade>
          </div>
          <div className='flex flex-col items-center bg-[#f3f3f3] overflow-hidden py-[30px] gap-[30px] w-full'>
            <div className='bg-[#fff] min-h-[100px] py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] w-full md:max-w-[920px] px-[10px] sm:min-w-full grid grid-cols-[200px_1fr] xs:grid-cols-[80px_1fr] xs:gap-[10px] items-center hover:scale-[1.05] transition'>
              <img src={amlogic} alt="Amlogic" className='justify-self-center' />
              <div className='flex flex-col h-full'>
                <span className='text-[1.5rem] font-bold'>SoC Amlogic</span>
                <span>Widevine DRM L3 L2 L1</span>
                <span>Modelos S905W2 S905X S905Y</span>
              </div>
            </div>
            <div className='bg-[#fff] min-h-[100px] py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] w-full md:max-w-[920px] px-[10px] sm:min-w-full grid grid-cols-[200px_1fr] xs:grid-cols-[80px_1fr] xs:gap-[10px] items-center hover:scale-[1.05] transition'>
              <img src={allwinner} alt="Allwinner" className='justify-self-center' />
              <div className='flex flex-col h-full'>
                <span className='text-[1.5rem] font-bold'>SoC	Allwinner</span>
                <span>Widevine DRM L3 L2 L1</span>
                <span>Modelos H616 H313</span>
              </div>
            </div>
            <div className='bg-[#fff] min-h-[100px] py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] w-full md:max-w-[920px] px-[10px] sm:min-w-full grid grid-cols-[200px_1fr] xs:grid-cols-[80px_1fr] xs:gap-[10px] items-center hover:scale-[1.05] transition'>
              <img src={rockchip} alt="Rockchip" className='justify-self-center' />
              <div className='flex flex-col h-full'>
                <span className='text-[1.5rem] font-bold'>SoC	Rockchip</span>
                <span>Widevine DRM L3 L2 L1</span>
                <span>Modelos R3288 R3368 R3399</span>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center bg-1 bg-no-repeat bg-cover py-[30px] w-full px-[10px]'>
          <span className='text-[2.6rem] text-[#0794d9] font-bold text-center'>Marcas probadas</span>
          <img src={marcas} alt="Marcas Probadas" />
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default SuscriberTerminalsPage