/**
 * @file HomePage.tsx
 * @description Home page
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import { useState } from 'react'
import { Fade } from 'react-reveal'
import { Link } from 'react-router-dom'
import portada from '../video/portada.mp4'
import portadaImg from '../image/portada.png'
import click from '../image/click.png'
import logosTvMUX from '../image/logosTvMUX.png'
import logosIpMUX from '../image/logosIpMUX.png'
import InfoBOXLogo from '../image/InfoBOXLogo.png'
import logosInfoBOX from '../image/logosInfoBOX.png'
import CableBOXLogo from '../image/CableBOXLogo.png'
import logosCableBOX from '../image/logosCableBOX.png'
import VnubeBOXLogo from '../image/VnubeBOXLogo.png'
import logosVnubeBOX from '../image/logosVnubeBOX.png'
import iptv from '../video/IpTV.mp4'
import ott from '../video/OTT.mp4'
import iptvImg from '../image/IpTV.png'
import ottImg from '../image/OTT.png'
import React from 'react'
import '../env.d.tsx'

const HomePage = (): JSX.Element => {
  const [full, setFull] = useState(null)
  const handleClick = (type) => {
    if (full !== type) {
      setFull(type)
    } else {
      setFull(null)
    }
  }
  return (
    <>
      <Header />
      <Main className="py-[0!important]">
        <div className='w-full h-[calc(100vh-70px)] min-h-[400px] flex justify-center items-center flex-col text-[2.9rem] font-bold relative'>
          <div className='absolute top-0 left-0 w-full h-full flex justify-start items-center sm:justify-center z-10'>
            <div className='lg:ml-[18vw] md:ml-[5vw] text-center'>
              <span className='flg'><span>IpTV</span> <span>OTT</span> A UN CLICK <img src={click} alt="Click" className='h-[40px] inline-block' /></span>
            </div>
          </div>
          <video playsInline muted loop autoPlay poster={portadaImg} className="h-full w-full object-cover absolute top-0 left-0 z-0 sm:opacity-50 sm:object-right">
            <source src={portada} />
          </video>
        </div>
        <div className={(full !== null ? "flex-col" : "flex-row") + ' flex sm:flex-col justify-center items-center w-full mt-[20px] px-[10px] bg-[#fff] md:rounded-br-[200px]'}>
          <video playsInline muted autoPlay loop poster={iptvImg} onClick={() => handleClick("iptv")} className={(full === "iptv" ? "max-w-[100%]" : "max-w-[50%]") + " transition-all sm:max-w-full cursor-pointer"}>
            <source src={iptv} />
          </video>
          <video playsInline muted autoPlay loop poster={ottImg} onClick={() => handleClick("ott")} className={(full === "ott" ? "max-w-[100%]" : "max-w-[50%]") + " transition-all sm:max-w-full cursor-pointer"}>
            <source src={ott} />
          </video>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover py-[80px] sm:gap-[50px]">
          <Link to="/TvMUX" className='contents'><i className='icon i-mini i-tvmux md:mr-[200px] md:justify-self-end'></i></Link>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <p className='mb-[20px] text-[#0794d9] font-bold text-[2rem] sm:text-[1.8rem]'>Transcodificador - TvMUX</p>
              <p className='max-w-[1400px]'>Transcodificación Compresion</p>
              <p className='flg max-w-[1400px]'><span>Multicast</span> <span>Unicast</span></p>
              <img src={logosTvMUX} alt="TvMUX" />
            </div>
          </Fade>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-[#f3f3f3] py-[80px] sm:gap-[50px] md:rounded-tl-[200px]">
          <Fade left>
            <div className='md:justify-self-end max-w-[622px] sm:row-start-1'>
              <p className='mb-[20px] text-[#0794d9] font-bold text-[2rem] sm:text-[1.8rem]'>Transmisión - IpMUX</p>
              <p className='flg max-w-[1400px]'>Gestión Suscriptores <span>IpTV</span> <span>OTT</span></p>
              <p className='flg max-w-[1400px]'><span>Planes</span> <span>Programas</span> <span>Promociones</span></p>
              <img src={logosIpMUX} alt="IpMUX" />
            </div>
          </Fade>
          <Link to="/IpMUX" className='contents'><i className='icon i-mini i-ipmux md:ml-[200px] md:justify-self-start'></i></Link>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover py-[80px] sm:gap-[50px]">
          <Link to="/InfoBOX" className='contents'><i className='icon i-mini i-infobox md:mr-[200px] md:justify-self-end'></i></Link>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <img src={InfoBOXLogo} alt="InfoBOX" className="mb-[20px]" />
              <p className='max-w-[1400px]'>Aplicativo para gestión empresarial</p>
              <p className='flg max-w-[1400px]'><span>Comercial</span> <span>Financiero</span> <span>Operacional</span> <span>Administrativo</span> <span>Inferfaces</span> <span>Contables</span> <span>Reporteria</span></p>
              <img src={logosInfoBOX} alt="InfoBOX" />
            </div>
          </Fade>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-[#f3f3f3] py-[80px] sm:gap-[50px] md:rounded-tr-[200px]">
          <Fade left>
            <div className='md:justify-self-end max-w-[622px] sm:row-start-1'>
              <img src={CableBOXLogo} alt="CableBOX" className="mb-[20px]" />
              <p className='flg max-w-[1400px]'><span>Aprovisionamiento</span> <span>DOCSIS</span> <span>EoC</span> <span>DvBC</span> <span>WISP</span> <span>EPON</span> <span>GPON</span> <span>IpTV</span> <span>OTT</span></p>
              <img src={logosCableBOX} alt="CableBOX" />
            </div>
          </Fade>
          <Link to="/CableBOX" className='contents'><i className='icon i-mini i-cablebox md:ml-[200px] md:justify-self-start'></i></Link>
        </div>
        <div className="grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover py-[80px] sm:gap-[50px]">
          <i className='icon i-mini i-vnubebox md:mr-[200px] md:justify-self-end'></i>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <img src={VnubeBOXLogo} alt="VnubeBOX" className="mb-[20px]" />
              <p className='max-w-[1400px] flg'><span>Maquinas Virtuales</span> <span>VPS</span> <span>Contenedor</span> <span>LXC</span> <span>DOCKER</span> <span>KUBERNET</span></p>
              <div className='grid grid-cols-2 mt-[20px] gap-[30px]'>
                <div className='flg'>
                  <p><span>Windows Server</span> 12 16 19 21</p>
                  <p><span>Linux</span></p>
                  <p><span>SLES</span> <span>RED</span> <span>HAT</span> <span>CANONICAL</span> <span>UBUNTU</span></p>
                </div>
                <div className='flg'>
                  <p><span>SQL SERVER</span></p>
                  <p><span>ORACLE</span> <span>INFORMIX</span> <span>DB2</span></p>
                  <p><span>MySQL</span> <span>POSTGRESQL</span></p>
                </div>
              </div>
              <img src={logosVnubeBOX} alt="VnubeBOX" />
            </div>
          </Fade>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default HomePage