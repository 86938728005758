/**
 * @file DemoAdminPage.tsx
 * @description Demo Admin Page
 * TODO: Implement the Demo Admin Page (Revisar todo esto)
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import { Formik, Form, Field } from 'formik'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup'
import React from 'react'

const DemoAdminPage = (): JSX.Element => {
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isDisabledExtender, setIsDisabledExtender] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [isExpired, setIsExpired] = useState(false)
  const [isLogin, setIsLogin] = useState<boolean | null>(null)
  const MySwal = withReactContent(Swal)

  const handleClickExtender = async () => {
    setIsDisabledExtender(true)

    const config = {
      method: "post",
      url: "../server/mailControler.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        values: {
          email: email
        },
        action: "extender_demo"
      }
    }
    await axios(config)
      .then(res => {
        setIsDisabledExtender(false)
        if (res.data.ok) {
          setIsExpired(false)
          MySwal.fire({
            title: <strong>Se ha Extendido la Demo Satisfactoriamente</strong>,
            icon: 'success',
            iconColor: '#0794d9',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#0794d9'
          })
        }
        else if (res.data.err) {
          MySwal.fire({
            title: <strong>Ha ocurrido un error</strong>,
            text: res.data.err,
            icon: 'error',
            iconColor: '#c53030',
            confirmButtonText: 'Cerrar',
            confirmButtonColor: '#c53030'
          })
        }
      })
      .catch(err => {
        setIsDisabledExtender(false)
        console.error(err)
        MySwal.fire({
          title: <strong>Ha ocurrido un error</strong>,
          text: 'Error Interno.',
          icon: 'error',
          iconColor: '#c53030',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#c53030'
        })
      })
  }

  const handleClickLogout = async () => {
    const config = {
      method: "post",
      url: "../server/mailControler.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        action: "cerrar_sesion"
      }
    }
    await axios(config)
      .then(res => {
        navigate('/')
      })
      .catch(err => {
        console.error(err)
        MySwal.fire({
          title: <strong>Ha ocurrido un error</strong>,
          text: 'Error Interno.',
          icon: 'error',
          iconColor: '#c53030',
          confirmButtonText: 'Cerrar',
          confirmButtonColor: '#c53030'
        })
      })
  }

  useEffect(() => {
    const config = {
      method: "post",
      url: "../server/mailControler.php",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        action: "validar_login"
      }
    }
    axios(config)
      .then(res => {
        if (res.data.ok) {
          setIsLogin(true)
          setEmail(res.data.info_demo.email)
          setName(res.data.info_demo.name)
          setPhone(res.data.info_demo.phone)
          setCompany(res.data.info_demo.company)
          setIsExpired(res.data.info_demo.expirado)
        } else if (res.data.err) {
          setIsLogin(false)
        }
      })
      .catch(err => {
        console.error(err)
      })

  }, [])

  return (
    <>
      <Header page="Solicitar Demo" />
      <Main className="flex items-center justify-center min-h-[700px] bg-1 bg-no-repeat bg-cover">
        <Formik
          initialValues={{
            user: '',
            pass: ''
          }}
          validationSchema={
            yup.object().shape({
              user: yup.string().required('Ingresa el usuario'),
              pass: yup.string().required('Ingresa la contraseña')
            })
          }
          onSubmit={async (values, actions) => {
            setIsDisabled(true)
            const config = {
              method: "post",
              url: "../server/mailControler.php",
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              data: {
                values,
                action: "login_demo"
              }
            }
            await axios(config)
              .then(res => {
                console.log(res.data)
                setIsDisabled(false)
                if (res.data.ok) {
                  setIsLogin(true)
                  setEmail(res.data.info_demo.email)
                  setName(res.data.info_demo.name)
                  setPhone(res.data.info_demo.phone)
                  setCompany(res.data.info_demo.company)
                  setIsExpired(res.data.info_demo.expirado)
                }
                else if (res.data.err) {
                  MySwal.fire({
                    title: <strong>Ha ocurrido un error</strong>,
                    text: res.data.err,
                    icon: 'error',
                    iconColor: '#c53030',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#c53030'
                  })
                }
              })
              .catch(err => {
                setIsDisabled(false)
                console.error(err)
                MySwal.fire({
                  title: <strong>Ha ocurrido un error</strong>,
                  text: 'Error Interno.',
                  icon: 'error',
                  iconColor: '#c53030',
                  confirmButtonText: 'Cerrar',
                  confirmButtonColor: '#c53030'
                })
              })

            actions.resetForm()
          }}
        >
          {({ errors, touched }) => (
            <Form className={(isLogin == null || isLogin ? 'hidden' : '')}>
              <div className='sm:min-w-full min-h-[350px] min-w-[530px] rounded-[15px] border-[6px] border-[#0794d9] text-center p-[20px] flex flex-col gap-[20px] bg-[#fff] sm:gap-[5px] sm:py-[5px]'>
                <div><span className='text-[2.2rem] font-bold'>Administra Tu Demo</span></div>
                <div className='flex flex-col text-left gap-[5px]'>
                  <label className='font-bold text-[1.1rem]' htmlFor="user">Usuario</label>
                  <Field type="text" className={(errors.user && touched.user ? "border-[#c53030] " : "") + 'input'} placeholder='Usuario' name='user' id="user" />
                  {errors.user && touched.user ? <label className='font-bold text-[1.1rem] text-[#c53030]' htmlFor="name">{errors.user}</label> : null}
                </div>
                <div className='flex flex-col text-left gap-[5px]'>
                  <label className='font-bold text-[1.1rem]' htmlFor="pass">Contraseña</label>
                  <Field type="password" className={(errors.pass && touched.pass ? "border-[#c53030] " : "") + 'input'} placeholder='Contraseña' name='pass' id="pass" />
                  {errors.pass && touched.pass ? <label className='font-bold text-[1.1rem] text-[#c53030]' htmlFor="pass">{errors.pass}</label> : null}
                </div>
                <div>
                  {
                    isDisabled
                      ?
                      <button type='submit' className='bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]' disabled>Cargando...</button>
                      :
                      <button type='submit' className='bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]'>Inciar Sesion</button>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: name,
            email: '',
            phone: phone,
            company: company
          }}
          validationSchema={
            yup.object().shape({
              name: yup.string()
                .min(2, 'No valido')
                .max(50, 'No valido')
                .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi, 'El nombre no puede contener números/signos')
                .required('Es requerido'),
              company: yup.string()
                .min(2, 'No valido')
                .max(50, 'No valido')
                .required('Es requerido'),
              phone: yup.string().matches(/^[+]?[(]?[1-9]{1}[)]?[0-9]{2}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, 'No valido').required("Es requerido")
            })
          }
          onSubmit={async (values, actions) => {
            values.email = email
            setIsDisabled(true)
            const config = {
              method: "post",
              url: "../server/mailControler.php",
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              data: {
                values,
                action: "actualizar_datos_demo"
              }
            }
            await axios(config)
              .then(res => {
                setIsDisabled(false)
                if (res.data.ok) {

                  MySwal.fire({
                    title: <strong>Se han actualizado los datos correctamente</strong>,
                    icon: 'success',
                    iconColor: '#0794d9',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#0794d9'
                  })
                }
                else if (res.data.err) {
                  console.error(res.data.err)
                  MySwal.fire({
                    title: <strong>Ha ocurrido un error</strong>,
                    text: res.data.err,
                    icon: 'error',
                    iconColor: '#c53030',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#c53030'
                  })
                }
              })
              .catch(err => {
                setIsDisabled(false)
                console.error(err)
                MySwal.fire({
                  title: <strong>Ha ocurrido un error</strong>,
                  text: 'Error Interno.',
                  icon: 'error',
                  iconColor: '#c53030',
                  confirmButtonText: 'Cerrar',
                  confirmButtonColor: '#c53030'
                })
              })
          }}
        >
          {({ errors, touched, values }) => (
            <Form className={(isLogin == null || !isLogin ? 'hidden' : '')}>
              <div className='sm:min-w-full min-h-[350px] min-w-[530px] rounded-[15px] border-[6px] border-[#0794d9] text-center p-[20px] flex flex-col gap-[20px] bg-[#fff] sm:gap-[5px] sm:py-[5px]'>
                <div><span className='text-[2.2rem] font-bold'>Administrando: {email}</span></div>
                <div className='flex justify-around items-center'><span className={(isExpired ? 'text-[#c53030] ' : 'text-[#0794d9] ') + 'text-[1.4rem] font-bold'}><p>{(isExpired ? 'Su Demo ha Expirado' : 'Su Demo sigue Vigente')}</p></span>{
                  isExpired
                    ?
                    (
                      isDisabledExtender
                        ?
                        <button type='button' onClick={handleClickExtender} className='max-h-[50px] bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]' disabled>Cargando...</button>
                        :
                        <button type='button' onClick={handleClickExtender} className='max-h-[50px] bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]'>Extender</button>
                    )
                    :
                    <button type='button' disabled className='max-h-[50px] bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]'>Extender</button>
                }</div>
                <div>
                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[1.1rem]' htmlFor="name">Nombre *</label>
                    <Field type="text" className={(errors.name && touched.name ? "border-[#c53030] " : "") + 'input'} placeholder='Nombre' value={values.name} name='name' id="name" />
                    {errors.name && touched.name ? <label className='font-bold text-[1.1rem] text-[#c53030]' htmlFor="name">{errors.name}</label> : null}
                  </div>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-1 gap-[10px]'>
                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[1.1rem]' htmlFor="phone">Teléfono *</label>
                    <div>
                      <Field type="text" className={(errors.phone && touched.phone ? "border-[#c53030] " : "") + 'input'} placeholder='Número de Teléfono' value={values.phone} name='phone' id="phone" />
                    </div>
                    {errors.phone && touched.phone ? <label className='font-bold text-[1.1rem] text-[#c53030]' htmlFor="phone">{errors.phone}</label> : null}
                  </div>

                  <div className='flex flex-col text-left gap-[5px]'>
                    <label className='font-bold text-[1.1rem]' htmlFor="company">Empresa *</label>
                    <Field type="text" className={(errors.company && touched.company ? "border-[#c53030] " : "") + 'input'} placeholder='Nombre Empresa' value={values.company} name='company' id="company" />
                    {errors.company && touched.company ? <label className='font-bold text-[1.1rem] text-[#c53030]' htmlFor="company">{errors.company}</label> : null}
                  </div>
                </div>
                <div className='flex flex-row gap-[20px] justify-center'>
                  {
                    isDisabled
                      ?
                      <button type='submit' className='bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]' disabled>Cargando...</button>
                      :
                      <button type='submit' className='bg-[#0794d9] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]'>Guardar</button>
                  }
                  <button type='button' className='bg-[#c53030] text-[#fff] p-[10px_40px] rounded-[6px] text-[1.2rem]' onClick={handleClickLogout}>Cerrar Sesión</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Main>
      <Footer />
    </>
  )
}

export default DemoAdminPage