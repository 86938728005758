/**
 * @file ApiPage.tsx
 * @description Page for API
 */

import Main from '../../components/Main.tsx'
import Footer from '../../components/Footer.tsx'
import Header from '../../components/Header.tsx'
import { FaWrench } from 'react-icons/fa/index.js'
import React from 'react'

const ApiPage = (): JSX.Element => {
  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>En mantenimiento</span>
        <FaWrench className='text-[3rem]' />
      </Main>
      <Footer />
    </>
  )
}

export default ApiPage