/**
 * @file Footer.tsx
 * @description Footer component
 */

import { Link } from 'react-router-dom'
import logo from '../image/logo.png'
import googleplay from '../image/googleplay.png'
import LGContentStore from '../image/LGContentStore.png'
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa/index.js'
import React from 'react'

const Footer = (props): JSX.Element => {
  return (
    <>
      <footer className='text-[#fff] py-[30px] bg-[#242f3e] md:rounded-tr-[200px] lg:rounded-tr-[500px] md:pl-[50px] md:pr-[120px] lg:px-[300px] sm:px-[10px]'>
        <div className='w-full flex justify-between'>
          <div className='xs:justify-center xs:flex'>
            <Link to="/"><img src={logo} alt="IoPLAY" width="80" height="80" className=' min-w-[60px]' /></Link>
          </div>
          <ul className='lg:gap-[40px] md:gap-[20px] flex flex-row sm:gap-[20px] sm:flex-wrap ml-[20px]'>
            <li><Link to="/SobreIoPLAY" className={(props.page === 'Sobre IoPLAY' ? 'border-b-[#0794d9] border-b-[3px]' : '') + ' hover:border-b-[#0794d9] hover:border-b-[3px]'}>Empresa</Link></li>
            <li><Link to="/Contacto" className={(props.page === 'Contacto' ? 'border-b-[#0794d9] border-b-[3px]' : '') + ' hover:border-b-[#0794d9] hover:border-b-[3px]'}>Contacto</Link></li>
            <li><Link to="/AsistenciaTecnica" className={(props.page === 'Asistencia Técnica' ? 'border-b-[#0794d9] border-b-[3px]' : '') + ' hover:border-b-[#0794d9] hover:border-b-[3px]'}>Asistencia Técnica</Link></li>
            <li><Link to="/AvisoLegal" className={(props.page === 'Aviso Legal' ? 'border-b-[#0794d9] border-b-[3px]' : '') + ' hover:border-b-[#0794d9] hover:border-b-[3px]'}>Aviso Legal</Link></li>
          </ul>
          <div className='flex flex-col xs:hidden ml-[10px]'>
            <span>Descarga nuestras Apps</span>
            <Link to="/AndroidApp">
              <img src={googleplay} alt="Android App" width="120" height="40" />
            </Link>
            <Link to="/LGApp">
              <img src={LGContentStore} alt="LG App" width="120" height="40" />
            </Link>
          </div>
        </div>
        <div className='flex-col hidden xs:flex w-full items-center mt-[40px]'>
          <span>Descarga nuestras Apps</span>
          <Link to="/AndroidApp">
            <img src={googleplay} alt="Android App" width="120" height="40" />
          </Link>
          <Link to="/LGApp">
            <img src={LGContentStore} alt="LG App" width="120" height="40" />
          </Link>
        </div>
        <div className='gap-[20px] flex sm:flex-col flex-row mt-[40px]'>
          <ul className='gap-[20px] flex flex-row text-[2rem] text-[#0794d9]'>
            <li><Link className="hover:text-[#fff]" to="/Twitter"><FaTwitter /></Link></li>
            <li><Link className="hover:text-[#fff]" to="/Facebook"><FaFacebook /></Link></li>
            <li><Link className="hover:text-[#fff]" to="/Instagram"><FaInstagram /></Link></li>
          </ul>
          <p className='flg'>© Copyright Todos los derechos reservados. 2025 I<em>o</em>PLAY.</p>
        </div>
        <div className='mt-[40px]'>
          <p className='flg'>I<em>o</em>PLAY I<em>o</em>PLAY <em>GO</em> <span>TvMUX</span> <span>IpMUX</span> <span>InfoBOX</span> <span>CableBOX</span></p>
          <p>Empresas y aplicativos registrados legalmente y protegidas por las leyes comerciales, regulativas y de propiedad intelectual</p>
        </div>
      </footer>
    </>
  )
}

export default Footer