/**
 * @file TvMUXPage.tsx
 * @description Page for TvMUX
 */

import Main from '../../components/Main.tsx'
import Footer from '../../components/Footer.tsx'
import Header from '../../components/Header.tsx'
import TvMUX from '../../image/TvMUX.png'
import TvMUXImg from '../../image/TvMUX_Operacion.png'
import TvMUXAnim1 from '../../video/TvMUX.mp4'
import TvMUXAnim2 from '../../video/TvMUX_no_inicio.mp4'
import { Zoom, Fade } from 'react-reveal'
import Check from '../../components/Check.tsx'
import { useState } from 'react'
import React from 'react'
import '../../env.d.tsx'

const TvMUXPage = (): JSX.Element => {
  const [introduction, setIntroduction] = useState(true)
  const handleOnEnded = () => {
    setIntroduction(false)
  }
  return (
    <>
      <Header page="TvMUX" />
      <Main className="py-[0!important]">
        <div className="h-[calc(100vh-70px)] min-h-[700px] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover">
          <Zoom><i className='md:justify-self-end md:mr-[100px] icon i-tvmux'></i></Zoom>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <span className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>Transcodificador - TvMUX</span>
              <p className='flg text-justify'>Servicio de transcodificación, del origen de programas de múltiples formatos estándar para servicios de transmisión para <span>IpTV</span> <span>OTT</span></p>
              <p className='flg text-justify'><span>MULTICAST</span>: [<span>UDP</span> <span>RTP</span>]</p>
              <p className='flg text-justify'><span>UNICAST</span>: [<span>DASH</span> <span>FILE</span> <span>HLS</span> <span>HTTP</span> <span>RTMP</span> <span>RIST</span> <span>SRT</span> <span>RTSP</span>]</p>
            </div>
          </Fade>
        </div>
        <div className="bg-[#f3f3f3] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden py-[80px] text-[.9rem] sm:gap-[50px] md:rounded-br-[200px]">
          <Fade left>
            <div className='md:justify-self-end max-w-[622px] sm:row-start-1'>
              <p className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>Manejo Operativo</p>
              <p className='font-bold text-[2.8rem] sm:text-[2.6rem] flg'><span>TvMUX</span></p>
              <ul className='grid grid-cols-2 gap-[10px] xs:grid-cols-1'>
                <Check>Portal <span>TvMUX</span></Check>
                <Check><span>Creación</span> <span>Borrado</span> <span>Edición</span> de programas</Check>
                <Check>Transcodificación <span>x86_64</span> <span>FFMPEG</span></Check>
                <Check>Transcodificación Aceleradores <span>ARM</span> <span>FFMPEG</span></Check>
                <Check>Inserción de METADATOS para marcas de tiempo e información adyacente</Check>
                <Check><span>Importación</span> <span>Exportación</span> de plantillas de ingesta</Check>
                <Check>Calendario de alternancia de emisión de programas</Check>
                <Check>Inserción EPG</Check>
                <Check>
                  <p>Inserción de contenido o ingesta de origen múltiple formato</p>
                  <p><span>MULTICAST</span>: [<span>UDP</span> <span>RTP</span>]</p>
                  <p><span>UNICAST</span>: [<span>DASH</span> <span>FILE</span> <span>HLS</span> <span>HTTP</span> <span>RTMP</span> <span>RIST</span> <span>SRT</span> <span>RTSP</span>]</p>
                </Check>
                <Check>
                  <p>Salida múltiples formatos de contenido transcodificado o no transcodificado</p>
                  <p><span>MULTICAST</span>: [<span>UDP</span> <span>RTP</span>]</p>
                  <p><span>UNICAST</span>: [<span>DASH</span> <span>FILE</span> <span>HLS</span> <span>HTTP</span> <span>RTMP</span> <span>RIST</span> <span>SRT</span> <span>RTSP</span>]</p>
                </Check>
                <Check>Copia y restauración de DB</Check>
                <Check>Lectura en línea de recursos de red y procesamiento</Check>
                <Check>Pre visualización en portal de contenido entrante y saliente</Check>
                <Check>Parametrización de recursos</Check>
                <Check>Alertas vía correo electrónico</Check>
              </ul>
            </div>
          </Fade>
          <Fade right><img src={TvMUX} alt="TvMUX" className='md:ml-[100px] md:justify-self-start w-[35vw] sm:w-full' /></Fade>
        </div>
        <div className='flex flex-col justify-center items-center w-full mt-[20px] px-[10px] bg-1 bg-no-repeat bg-cover py-[80px]'>
          <span className='flg text-[2.6rem] sm:text-[2rem] font-bold text-center'>
            <p>OPERACION <span>TvMUX</span></p>
          </span>
          <video playsInline muted autoPlay poster={TvMUXImg} className={(introduction ? "" : "hidden") + " max-w-[80%] sm:max-w-full"} onEnded={handleOnEnded}>
            <source src={TvMUXAnim1} />
          </video>
          <video playsInline muted autoPlay loop poster={TvMUXImg} className={(introduction ? "hidden" : "") + " max-w-[80%] sm:max-w-full"}>
            <source src={TvMUXAnim2} />
          </video>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default TvMUXPage