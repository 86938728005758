/**
 * @file APPPage.tsx
 * @description Page for APP
 */

import Main from '../../components/Main.tsx'
import Footer from '../../components/Footer.tsx'
import Header from '../../components/Header.tsx'
import { Zoom, Fade } from 'react-reveal'
import Check from '../../components/Check.tsx'
import app from '../../image/app.png'
import appMarcas from '../../image/appMarcas.png'
import appMarcas2 from '../../image/appMarcas2.png'
import multiplataforma from '../../image/multiplataforma.png'
import React from 'react'

const APPPage = (): JSX.Element => {
  return (
    <>
      <Header page="APP" />
      <Main>
        <div className="h-[calc(100vh-70px)] min-h-[700px] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden bg-1 bg-no-repeat bg-cover">
          <Fade left>
            <div className='md:justify-self-end max-w-[622px] sm:row-start-1'>
              <span className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>APP</span>
              <p className='flg text-justify'>I<em>o</em>PLAY suministra una aplicación I<em>o</em>PLAY <em>GO</em> multi dispositivo [<span>TvBOX</span> <span>Tabletas</span> <span>Televisores</span> <span>Teléfonos</span>], sobre plataforma Android, Samsung, LG, SmartTV, FireTV y WebPC  con la cual se logra el acceso a los diferentes planes comerciales, contenidos emitidos por el cable operador, control de acceso [CAS], y seguridad DRM [Digital Right Management], en las modalidades comerciales y técnicas <span>IpTV</span> <span>OTT</span> para transmision de programas.</p>
              <img src={appMarcas} alt="Marcas APP" />
            </div>
          </Fade>
          <Zoom><img src={app} alt="APP" className='md:ml-[100px] md:justify-self-start w-[35vw] sm:w-full sm:max-w-[400px]' /></Zoom>
        </div>
        <div className="bg-[#f3f3f3] grid sm:justify-items-center md:grid-cols-2 items-center px-[10px] overflow-hidden py-[80px] text-[.9rem] sm:gap-[50px] md:rounded-br-[200px]">
          <Fade left><img src={appMarcas2} alt="APP" className='md:mr-[100px] md:justify-self-end w-[25vw] sm:w-full sm:max-w-[400px]' /></Fade>
          <Fade right>
            <div className='md:justify-self-start max-w-[622px] sm:row-start-1'>
              <p className='text-[#0794d9] font-bold text-[2.8rem] sm:text-[2.6rem]'>Manejo Operativo</p>
              <p className='font-bold text-[2.8rem] sm:text-[2.6rem] flg'><span>APP</span></p>
              <ul className='grid grid-cols-2 gap-[10px] xs:grid-cols-1'>
                <Check>Modo Mantenimiento</Check>
                <Check>Modo <span>Configuración</span></Check>
                <Check>Modo <span>Configuración</span> ingesta <span>MULTICAST</span> <span>UNICAST</span></Check>
                <Check>Modo <span>Configuración</span> acceso <span>ID</span> <span>MAC</span> <span>SESION</span></Check>
                <Check>Modo <span>Configuración</span> portal CAS</Check>
                <Check>Modo <span>Configuración</span> <span>Reintento</span> transmisión actual <span>Reintento</span> transmisión  siguiente</Check>
                <Check>Modo <span>Configuración</span> lectura recursos en línea en pantalla</Check>
                <Check>Modo <span>Muestra</span> de parrilla de programación y panel numérico</Check>
              </ul>
            </div>
          </Fade>
        </div>
        <div className='flex flex-col justify-center items-center w-full mt-[20px] px-[10px] bg-1 bg-no-repeat bg-cover py-[80px]'>
          <span className='text-[2.6rem] sm:text-[2rem] font-bold text-center'>MODELO MULTI PLATAFORMA</span>
          <p className='text-justify max-w-[1400px] flg'>I<em>o</em>PLAY, puede coexistir con más operadores de contenido estándar, ubicándose a la par de opciones para servicios <span>OTT</span> normalizados</p>
          <Zoom><img src={multiplataforma} alt="Modelo Multiplataforma" /></Zoom>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default APPPage