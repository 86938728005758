/**
 * @file Redirect.tsx
 * @description Redirects to the specified URL
 */

import Main from '../components/Main.tsx'
import Footer from '../components/Footer.tsx'
import Header from '../components/Header.tsx'
import React, { useEffect } from 'react'

const AndroidAppRedirect = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.stream.ioplaygo'
  }, [])

  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>Redireccionando...</span>
      </Main>
      <Footer />
    </>
  )
}

const LGAppRedirect = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://co.lgappstv.com/main/tvapp/detail?appId=1219283'
  }, [])

  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>Redireccionando...</span>
      </Main>
      <Footer />
    </>
  )
}

const FacebookRedirect = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://www.facebook.com/IoPLAYGO'
  }, [])

  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>Redireccionando...</span>
      </Main>
      <Footer />
    </>
  )
}

const XRedirect = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://x.com/ioplaygo'
  }, [])

  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>Redireccionando...</span>
      </Main>
      <Footer />
    </>
  )
}

const InstagramRedirect = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://www.instagram.com/ioplaygo/'
  }, [])

  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>Redireccionando...</span>
      </Main>
      <Footer />
    </>
  )
}

export { AndroidAppRedirect, LGAppRedirect, FacebookRedirect, XRedirect, InstagramRedirect }