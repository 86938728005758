/**
 * @file Main.tsx
 * @description Main component
 */

import React from "react"

const Main = (props): JSX.Element => {
  return (
    <>
      <main className={(props.className ? props.className : '') + " mt-[70px] py-[10px] h-auto"}>
        {props.children}
      </main>
    </>
  )
}

export default Main